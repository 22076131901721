// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMClient from 'react-dom/client'
import { Link as BaseRRLink } from 'react-router-dom'

window.React = React
window.ReactDOM = ReactDOM
window.ReactDOMClient = ReactDOMClient

const RRLink = ({ href, ...rest }) =>
  React.createElement(BaseRRLink, { to: href, ...rest })

// eslint-disable-next-line fp/no-mutation, no-undef
globalThis.LINK_COMPONENTS = { internal: RRLink }
